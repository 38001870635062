
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

:root{
  --transition: all 0.7s ease;
  --yellow: #f9d342;
  --dark: #292826;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Playfair Display', serif !important;
}
html{
  scroll-behavior: smooth;
}
body{
  font-size: initial;
  line-height: 1.6;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;
}

.btn{
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 140px;
  display: block;
  margin: 15px auto;
  cursor: pointer;
  transition: var(--transition);
  text-decoration: none;
  background-color: #fff;
  text-align: center;
  color: black;
}
.btn:hover{
  opacity: 0.85;
  text-decoration: none;
}

.site-name {
  display: flex;
  justify-content: center;
  align-items: center;
}


.site-name img {
  width: 70px;
  margin-right: 20px;
}

.flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1, h2, h3, h4, h5, h6{
  padding: 8px 0;
}
img{
  width: 100%;
  display: block;
}
@media(max-width: 500px){
  body{
    font-size: 14px;
  }
}

/* header */
.header{
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url("../images/banner-img.jpg") center/cover no-repeat;
  min-height: 100vh;
  color: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.head-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-top a {
  text-decoration: none;
  color: inherit;
}
.head-top span{
  cursor: pointer;
  letter-spacing: 2px;
  transition: var(--transition);
}
.head-top span:hover{
  color: var(--yellow);
}
.head-bottom{
  flex: 1;
  text-align: center;
  width: 80vw;
  margin: 0 auto;
}
.head-bottom h2{
  padding: 22px 0;
  font-size: 45px;
  border-bottom: 1px solid #fff;
}
.head-bottom p{
  opacity: 0.7;
  font-size: 20px;
  margin: 45px auto;
  width: 60vw;
  line-height: 25px;
}
.head-btn{
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
  border: 3px solid #fff;
  background: transparent;
  padding: 13px 20px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  cursor: pointer;
  transition: var(--transition);
  text-decoration: none;
}
.head-btn:hover{
  background: transparent;
}
@media(max-width: 500px){
  .head-btn{
    font-size: 17px;
  }
  .head-bottom h2{
    font-size: 28px;
  }
  .head-bottom p{
    font-size: 17px;
    margin: 20px auto;
  }
}

/* side nav */
.sidenav{
  background: var(--dark);
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  padding: 20px;
  transition: var(--transition);
  z-index: 10;
}

/********/
.sidenav.show{
}
/*************/
.cancel-btn{
  position: absolute;
  right: 25px;
  font-size: 22px;
  cursor: pointer;
  transition: var(--transition);
}
.cancel-btn:hover{
  opacity: 0.7;
}
.navbar{
  margin-top: 50px;
  list-style: none;
  padding: 12px;
}
.navbar li a{
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  padding: 15px 0;
  display: block;
  border-bottom: 0.5px solid rgba(249, 211, 66, 0.3);
  opacity: 0.8;
  letter-spacing: 1.5px;
  transition: var(--transition);
}
.navbar li a:hover{
  padding-left: 12px;
}
.sign-up, .log-in{
  background: var(--yellow);
  color: #fff;
}
@media(max-width: 400px){
  .sidenav{
    width: 100vw;
  }
}

/** title */
.title{
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 30px;
  color: var(--yellow);
  border-bottom: 5px solid var(--dark);
  width: 235px;
  margin: 15px 0;
}

.sec-width{
  width: 85vw;
  margin: 0 auto!important;
}

/* service */
.services{
  margin: 40px 0;
}
.service{
  display: flex;
  margin: 30px 0;
}
.service-icon{
  align-self: flex-start;
  font-size: 48px;
  color: #252525;
}
.service-content{
  padding-left: 20px;
}
.service-content h2{
  color: black;
  opacity: 0.7;
}
.service-content button{
  margin-left: 0;
  margin-right: 0;
  background: var(--yellow);
  color: #fff;
}
@media(min-width: 992px){
  .services-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
  .title h2{
    font-size: 10px!important;
  }
}
@media(max-width: 500px){
  .title h2{
    font-size: 24px;
  }
}

/* booking form */
.book{
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url("../images/cuisine.jpeg") center/cover no-repeat;
  color: #fff;
  padding: 40px 60px;
  text-align: center;
}

.book p {
  font-size: 25px;
}

.book a {
  text-decoration: none;
  color: #fff
}

.book a:hover {
  text-decoration: none;
}

.book h2 {
  font-size: xx-large;
}

.form-item {
  font-size: xx-large;
}
.book-form{
  display: grid;
}
.form-item{
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.book-form input[type = "submit"]{
  margin: 18px 0 0 0;
  background: var(--dark);
  color: #fff;
  border: 1px solid #fff;
}
.book-form label, .book-form input, .book-form select{
  width: 100%;
}

.book-form label{
  font-weight: 600;
  word-spacing: 5px;
  padding-bottom: 8px;
}
.book-form input:not(.btn) , .book-form select{
  margin: 4px 0 16px 0;
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 17px;
  border: none;
  color: #1675e0
}

.book-form input:focus, .book-form select:focus{
  outline: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
}
@media(min-width: 768px){
  .book-form{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
}
@media(min-width: 1170px){
  .book-form{
    grid-template-columns: repeat(3, 1fr);
  }
}
@media(min-width: 1370px){
  .book-form{
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  }
  .book{
    height: 75vh;
    display: grid;
  }
}

@media(min-width: 768px){
  .book-form2{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
}
@media(min-width: 1170px){
  .book-form2{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media(min-width: 1370px){
  .book-form2{
    grid-template-columns: 2fr 2fr;
  }
}

@media(max-width: 500px){
  .book{
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* room */

.rooms{
  margin: 40px 0;
}
.room{
  margin: 45px 0;
  padding-bottom: 25px;
  background: var(--dark);
  color: #fff;
}
.room-text{
  padding: 5px 20px;
}
.room-text h3{
  font-size: 24px;
}
.room-text ul{
  list-style-type: none;
  margin: 30px 0;
}
.room-text ul li{
  padding: 7px 0;
  margin-top: 20px;
  font-size: 20px;
}
.room-text ul li i{
  color: var(--yellow);
  margin-right: 10px;
}
.rate{
  opacity: 0.8;
}
.rate span{
  font-size: 40px;
  font-weight: 900;
  color: var(--yellow);
}
.room-image{
  overflow: hidden;
}
.room-image img{
  transition: var(--transition);
}
.room-image:hover img{
  transform: scale(1.1);
}
@media (min-width: 900px){
  .rooms-container{
    display: flex;
    flex-wrap: wrap;
  }
  .room{
    flex: 0 0 calc(50% - 20px);
    margin: 10px;
  }
}
@media(min-width: 1370px){
  .rooms-container{
    flex-direction: column;
  }
  .room{
    display: flex;
    padding-bottom: 0;
    margin: 5px 0;
  }
  .room-image{
    flex: 1;
  }
  .room-image img{
    height: 100%;
  }
  .room-text{
    flex: 1;
  }
  .room:nth-child(even){
    flex-direction: row-reverse;
  }
}

/* customers */

.customers{
  margin-top: 40px;
  padding: 40px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/pain.jpg") center/cover no-repeat fixed;
}
.customer{
  background: #fff;
  padding: 35px;
  text-align: center;
  margin: 24px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: var(--transition);
}

.customer a{
  text-decoration: none;
  color: inherit;
}

.rating{
  margin: 10px 0;
  color: var(--yellow);
}
.customer h3{
  font-size: 25px;
}
.customer p{
  opacity: 0.7;
  line-height: 1.8;
  width: 60%;
  margin: 0 auto;
}
.customer img{
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin: 25px auto;
}
.customer span{
  font-weight: 700;
  opacity: 0.7;
}
.customer:hover{
  transform: translateY(-18px);
}
@media(min-width: 992px){
  .customers-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}
@media(min-width: 1370px){
  .customers-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media(max-width: 500px){
  .customer p{
    width: 100%;
  }
  .customer img{
    width: 100px;
    height: 100px;
  }
}

/* footer */
.footer{
  background: var(--dark);
  color: #fff;
  text-align: center;
}
.footer-container{
  width: 85vw;
  margin: 0 auto;
  padding: 30px 0;
}
.footer-container p, .footer-container a, .footer-container span{
  opacity: 0.8;
}
.footer a{
  color: #fff;
  text-decoration: none;
}
.social-icons{
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}
.social-icons li{
  margin: 0 10px;
  width: 50px;
  height: 50px;
  background: #2e2e2e;
  border-radius: 50%;
  transition: var(--transition);
  cursor: pointer;
}
.social-icons li:hover{
  background: #fff;
  color: #2e2e2e;
}
.contact-item span{
  display: block;
}
@media(min-width: 900px){
  .footer-container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
  }
}
@media(min-width: 1170px){
  .footer{
    text-align: left;
  }
  .footer-container{
    grid-template-columns: 2fr 1fr;
  }
  .footer-container div:nth-child(1) p{
    padding-right: 20px;
  }
  .contact-item{
    display: grid;
    grid-template-columns: 5% 95%;
    margin-bottom: 15px;
    align-items: center;
  }
}

.showModal{
  position: fixed;
  background: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.customDatePickerWidth,
.customDatePickerWidth > div,
.customDatePickerWidth div,
.customDatePickerWidth input,
.customDatePickerWidth span:not(.rs-picker-toggle-clean) {
  width: 100%;
  overflow: visible !important;
}
.customDatePickerWidth span
{
  display: block;
}